import React from "react";

const About = () => {
  return (
    <>
      <div className="container my-5">
        <div className="row align-items-center mb-5">
          <div className="col-md-6">
            <img
              src="/image/a.webp"
              alt="About us banner"
              className="about-img"
              style={{ width: "100%" }}
            />
          </div>

          <div className="col-md-6">
            <p>
              Welcome to [Your Business Name], your one-stop shop for [mention
              your product/service category]. Founded with the vision of making
              [specific value, e.g., "premium quality products" or "affordable
              luxury"] accessible to everyone, we take pride in offering a
              diverse range of [products/services] that cater to your needs and
              preferences. Our platform combines convenience, reliability, and
              exceptional customer service to ensure a seamless shopping
              experience. At [Your Business Name], we are committed to building
              lasting relationships with our customers by delivering not just
              products, but also trust and satisfaction. Thank you for choosing
              us as your go-to e-commerce destination!
            </p>
            <p>
              At [Your Business Name], we’re redefining online shopping by
              offering a seamless and trustworthy experience. Whether you’re
              looking for [specific product/service type], our platform is
              designed to meet your needs with quality, convenience, and care.
              Join us as we make shopping simpler, better, and more enjoyable
              for everyone
            </p>
          </div>
        </div>

        {/* Additional Content Below */}
        <div className="mb-5">
          <h2 style={{ color: "#0c7bc0", fontWeight: "600", padding: "10px" }}>
            Features
          </h2>
          <p>
            Advanced Imaging Services for accurate diagnosis and monitoring.
          </p>
          <p>
            Specialized Testing including blood work, urine analysis, and lab
            screenings.
          </p>
          

          <div className="mb-5">
            <h2
              style={{ color: "#0c7bc0", fontWeight: "600", padding: "10px" }}
            >
              Facility Features
            </h2>
            <ul>
              <li>
                State-of-the-Art Technology: Advanced systems to streamline
                operations and enhance efficiency.
              </li>
              <li>
                Climate-Controlled Storage: Ensures products are stored at
                optimal conditions
              </li>
              <li>
                Advanced Security Systems: 24/7 monitoring with surveillance
                cameras and access control.
              </li>
              <li>
                Ample Space and Flexible Layouts: Designed to meet diverse
                business needs.
              </li>
              <li>
                Sustainability Features: Energy-efficient design and
                eco-friendly practices
              </li>
              <li>
                Convenient Accessibility: Easily reachable location with ample
                parking and loading areas.
              </li>
              <li>
                Dedicated Customer Support Areas: Comfortable spaces for
                interactions and queries.
              </li>
              <li>
                High-Speed Connectivity: Reliable internet and communication
                systems throughout the facility. Let me know if you'd like to
                adjust or add more features specific to your facility!
              </li>
            </ul>
          </div>
          <div className="mb-5">
            <h2
              style={{ color: "#0c7bc0", fontWeight: "600", padding: "10px" }}
            >
              Diagnostic/Clinic Equipment
            </h2>
            <ul>
              <li>
                <strong>Diagnostic Equipment</strong>
              </li>
              <li>
                Ultrasound Machines: Used for imaging soft tissues, monitoring
                pregnancies, and diagnosing internal organ issues.
              </li>
              <li>
                X-Ray Systems: Essential for detecting fractures, infections,
                and abnormalities in bones and tissues.
              </li>
              <li>
                CT Scanners: Provide detailed cross-sectional imaging for
                accurate diagnosis of internal conditions.
              </li>
              <li>
                MRI Machines: Used for high-resolution imaging of soft tissues,
                including brain, muscles, and joints.
              </li>
              <li>
                ECG/EKG Machines: Monitor heart activity to detect irregular
                rhythms or heart conditions.
              </li>

              <li>
                <strong>Clinical Equipment</strong>
              </li>
              <li>
                Patient Monitors: Track vital signs like heart rate, blood
                pressure, oxygen levels, and more.
              </li>
              <li>
                Defibrillators: Used in emergencies to restore normal heart
                rhythms.
              </li>
              <li>
                Infusion Pumps: Ensure precise delivery of medications or fluids
                to patients.
              </li>
              <li>
                Autoclaves: Essential for sterilizing instruments and
                maintaining a sterile clinical environment.
              </li>
              <li>
                Lab Analyzers: Perform blood, urine, and other tests for
                accurate clinical diagnostics.
              </li>
            </ul>
          </div>
          <div className="mb-5">
            <h2
              style={{ color: "#0c7bc0", fontWeight: "600", padding: "10px" }}
            >
              Affiliated Diagnostic Center Capabilities
            </h2>
            <ul>
              <li>
                <strong>Advanced Imaging Services</strong>: Comprehensive
                imaging solutions including X-rays, Ultrasounds, CT Scans, and
                MRI.
              </li>
              <li>
                <strong>Specialized Testing</strong>: Accurate and timely
                diagnostic tests such as blood work, urine analysis, and lab
                screenings.
              </li>
              <li>
                <strong>Cardiac Care</strong>: High-quality ECG/EKG monitoring
                and stress testing for heart health assessment.
              </li>
              <li>
                <strong>Women’s Health</strong>: Specialized services like
                obstetric ultrasound and gynecological diagnostics.
              </li>
              <li>
                <strong>Oncology Support</strong>: Imaging and diagnostic
                support for cancer detection and treatment planning.
              </li>
              <li>
                <strong>Emergency Diagnostics</strong>: 24/7 availability for
                urgent diagnostic procedures and rapid test results.
              </li>
              <li>
                <strong>Personalized Patient Care</strong>: Tailored diagnostic
                solutions with dedicated support from trained professionals.
              </li>
              <li>
                <strong>Health Monitoring</strong>: Continuous monitoring
                services including vital signs and chronic disease management.
              </li>
              <li>
                <strong>Sterilized & Safe Environment</strong>: Advanced
                sterilization protocols ensuring a safe and clean diagnostic
                experience.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
